import BGPH1 from "../../assets/images/home/aboutusloan.jpeg";
import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Bredcom.css";

function Main({ title, subtitle, link }) {
  const [contactData, setContactData] = useState({});
  const [deviceType, setdeviceType] = useState(
    window.innerWidth > 1024 ? "desktop" : "mobile"
  );
  //get data
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybredcom"
      );

      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        console.log(element.title, subtitle);

        if (element.title === subtitle && element.device === deviceType) {
          setContactData(element);
          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {/* <section className=" new-pageheader">
        <div
          className="new-page-header__bg"
          style={{
            backgroundImage: `url(https://api.dremerz.net/uploads/${contactData.image})`,
          }}
        ></div>
      </section> */}
      <div className="image-box">
        <img
          src={`https://api.dremerz.net/uploads/${contactData.image}`}
          alt="Image description"
        />
      </div>
    </>
  );
}

export default Main;
